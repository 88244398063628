import request from '@/router/axios';
import {businessUrl} from "@/config/env"

//查询
export const list = (pageNo,pageSize,code,type) => {
    return request({
        url : businessUrl + "faultInformation/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            code: code,
            type
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "faultInformation/update",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "faultInformation/delete",
        method: "delete",
        params: {
            id
        }
    })
}
//新增
export const add = (row) => {
    return request({
        url : businessUrl + "faultInformation/insert",
        method: "post",
        data : {
            ...row
        }
    })
}
