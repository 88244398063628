<template>
  <div>
        <basic-container>
            <avue-crud :option="tableOption"
                    :data="tableData"
                    :table-loading="tableLoading"
                    :page="page"
                    ref="crud"
                    @current-change="currentChange"
                    @size-change="sizeChange"
                    @refresh-change="refreshChange"
                    @row-save="handleSave"
                    @row-update="handleUpdate"
                    @row-del="handleDel">
                <template slot="menuLeft">
                <importBtn
                    :outApi="this.proApis.FAULTINFORMATIONEXPORT"
                    :innerAPI="this.proApis.FAULTINFORMATIONEXPORTIMPORT"
                    :API="this.proApis.FAULTINFORMATIONEXPORTIMPORTTEMPLATE"
                    @refresh="refreshData"
                >
                </importBtn>
                </template>
                <template slot="menuRight">
                    <el-select filterable v-model="type" placeholder="请输入故障类型" clearable size="small" style="width:200px !important;marginRight: 5px;">
                    <el-option v-for="value in typeList" :key="value.id" :value="value.id" :label="value.name"></el-option>
                    </el-select>
                    <el-input v-model="code" :placeholder="$t('FAULT CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small">
                    </el-input>
                </template>
            </avue-crud>
        </basic-container>
  </div>
</template>
<script>
import { list, add, edit, remove } from "@/api/fault"
import importBtn from "@/components/importExport/importBtn";
export default {
    components:{
        importBtn
    },
    data() {
        return {
                typeList: [],
                tableLoading: false,
                tableLoading: false,
                code: "",
                type: "",
              page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                columnBtn: false,
                align: 'center',
                menuAlign: 'center',
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [
                    {
                        label: this.$t('FAULT CODE'),
                        prop: 'code',
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER FAULT CODE'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('FAULT TYPE'),
                        prop: 'type',
                        type:'select',
                        dicData:[],
                        props: {
                            label: "name",
                            value: "id"
                        },
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER FAULT TYPE'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('INFORMATION CONTENT'),
                        prop: "description",
                         rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER FAULT CODE INFORMATION'),
                            trigger: "blur"
                        }]
                    },
                    {
                        label: this.$t('CREATION TIME'),
                         editDisplay: false,
                          addDisplay: false,
                         prop: 'createTime'
                 
                    }
                ]
            }
        }
    },
    async created() {
      //所有故障类型
      const res = await this.request.get(this.proApis.SELECTDICTBYCATEGORYID + "?categoryId=10010");
      this.typeList = res.data.data;
      this.tableOption.column[1].dicData = res.data.data;
      console.log(this.typeList);
      this.list();
    },
    methods: {
            currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
         handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'),{
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(res => {
                    res.data.code=="0000"?this.$message({
                      type:"success",
                        message: this.$t('DeleteSuccess')
                    }):"";
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            //row.colorValue = hexify(row.colorValue);
            edit(row).then(res => {
                res.data.code=="0000"?this.$message({
                  type:"success",
                    message: this.$t('EDIT SUCCEEDED')
                }):"";
                this.list();
                done();
            })
        },
        handleSave(row, done) {
            //row.colorValue = hexify(row.colorValue);
            console.log(row)
            add(row).then(res => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          this.tableData = [];
          list(this.page.pageCurrent,this.page.pageSize,this.code,this.type).then(res => {
              this.page.total = res.data.data.total;
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
        
    }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>